import React, { useEffect, useState } from "react"

const FormField = ({ data, onChange, isErrors, clearInput }) => {
  const { title, fieldType, required } = data
  const [clearValue, setClearValue] = useState("")
  useEffect(() => {
    setClearValue("")
  }, [clearInput])

  if (fieldType === "Select") {
    if (data.values?.length > 0) {
      return (
        <label className="text-sm flex flex-col">
          {title || "Select"}:{required && " *"}
          <select
            className="mb-3.5 w-full input py-1 rounded"
            onChange={e => {
              onChange(e.target.value, data)
            }}
          >
            {data.values.map(option => (
              <option value={option}>{option}</option>
            ))}
          </select>
        </label>
      )
    }
    return null
  }

  return (
    <label className="text-sm">
      {title}:{required && " *"}
      {fieldType === "textarea" ? (
        <textarea
          onChange={e => {
            setClearValue(e.target.value)
            onChange(e.target.value, data)
          }}
          className={`mb-3.5 w-full rounded h-28 ${isErrors && "inputError"}`}
          value={clearValue}
        ></textarea>
      ) : (
        <input
          onKeyDown={e => {
            if (e.keyCode === 13) {
              e.preventDefault()
            }
          }}
          onChange={e => {
            setClearValue(e.target.value)
            onChange(e.target.value, data)
          }}
          className={`mb-3.5 input rounded w-full h-8 ${
            isErrors && "inputError"
          }`}
          type={fieldType}
          value={clearValue}
          pattern={fieldType === "tel" ? "[0-9|+]{9,13}" : ""}
        ></input>
      )}
    </label>
  )
}
export default FormField
